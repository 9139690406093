import React from "react";

//const pageSize = 20;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const NotificationList: React.FC<{ id: string }> = (/*{ id }*/) => {
  /*  const { t: tCompanies } = useTranslation("companies");
  const { t: tProfiles } = useTranslation("profiles");
  const { t: tCommon } = useTranslation("common");
  const { t: tNotifications } = useTranslation("notifications");
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, isLoading } = useQuery({});
  const totalNumberOfUsers = 0;
  const totalNumberOfPages = Math.ceil(totalNumberOfUsers / pageSize);
  const listOfNotifications = [];
  const iconProps = {
    width: "20px",
    height: "20px",
  };
  const formatDate = useFormatDate();
  const formattedDate = (date: any) => {
    const millisecondsToDateObject = new Date(Number(date));

    return formatDate(millisecondsToDateObject, "short");
  };*/
  /* return (
*    <StyledContainer>
       <>
         <StyledRoundedLeftContainer>
           <StyledTitle variant='title2'>
             {tCompanies("companies.list.subtitles.list")}
           </StyledTitle>
           <StyledDivider />
           <StyledSearchFieldContainer>
             <StyledSearchField
               dataTestId='text-field'
               onChange={(e) => setSearchTerm(e.target.value)}
               placeholder={tCompanies("companies.list.searchPlaceholder")}
               startIcon={<Search width={18} height={18} />}
               value={searchTerm}
             />
           </StyledSearchFieldContainer>
           <StyledCustomTableContainer>
             <StyledTableHeader>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.email")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.name")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.accountProfile")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.status")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.createdTs")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {tCompanies("companies.list.data.lastConnection")}
                 </StyledCellHeaderText>
               </StyledCell>
               <StyledCell>
                 <StyledCellHeaderText variant='text2'>
                   {""}
                 </StyledCellHeaderText>
               </StyledCell>
             </StyledTableHeader>

             <StyledTable>
               {listOfNotifications.length > 0 ? (
                 listOfNotifications.map((notification) => (
                   <StyledRowContainerLink
                     to={`/users/${notification.UserIdData?.id}`}
                     key={notification.id}
                   >
                     <StyledCell>
                       <StyledCellText variant='text2'>
                         {notification?.UserIdData?.email}
                       </StyledCellText>
                     </StyledCell>
                     <StyledCell>
                       <StyledCellText variant='text2'>
                         {`${notification?.UserIdData?.firstName} ${notification?.UserIdData?.lastName}`}
                       </StyledCellText>
                     </StyledCell>
                     <StyledCell>
                       {isRolesProfileCode(
                         notification?.Subscriptions?.[0]?.RolesProfileIdData?.code ??
                           "",
                       ) ? (
                         <RolesProfileIcon
                           code={
                             (notification?.Subscriptions?.[0]?.RolesProfileIdData
                               ?.code as
                               | "ADMIN"
                               | "MANAGER"
                               | "ACCOUNTANT"
                               | "VIEWER") ?? ""
                           }
                           size={28}
                         />
                       ) : null}
                       <StyledCellText variant='text2'>
                         {getUserRoleDisplayedName({
                           code: notification?.Subscriptions?.[0]?.RolesProfileIdData
                             ?.code,
                           name: notification?.Subscriptions?.[0]?.RolesProfileIdData
                             ?.name,
                           t: tProfiles,
                         })}
                       </StyledCellText>
                     </StyledCell>
                     <StyledCell>
                       <StyledCellText variant='text2'>
                         <BubbleText
                           text={capitalize(
                             tCompanies(
                               `companies.list.status.${notification.status}.label`.toLowerCase(),
                             ),
                           )}
                           variant={
                             notification.status.toLowerCase() === "active"
                               ? "green"
                               : "grey"
                           }
                         />
                       </StyledCellText>
                     </StyledCell>
                     <StyledCell>
                       <StyledCellText variant='text2'>
                         {formattedDate(notification?.UserIdData?.createdTs)}
                       </StyledCellText>
                     </StyledCell>
                     <StyledCell>
                       <StyledCellText variant='text2'>
                         {formattedDate(notification?.enabledLastTs)}
                       </StyledCellText>
                     </StyledCell>

                     <StyledCellWithEndArrow>
                       <Text variant='text2'>
                         <StyledRightIcon />
                       </Text>
                     </StyledCellWithEndArrow>
                   </StyledRowContainerLink>
                 ))
               ) : (
                 <EmptyDataInDB principalText={tCommon("common.noData")} />
               )}
             </StyledTable>
           </StyledCustomTableContainer>
           <Loading isLoading={isLoading}>
             {totalNumberOfPages > 1 && currentPage < totalNumberOfPages ? (
               <StyledButtonContainer>
                 <StyledButton
                   leftIcon={<Subtract {...iconProps} />}
                   variant='secondary'
                   buttonText={tCommon("common.pagination.next_page", {
                     pageSize,
                   })}
                   isLowercase={true}
                   onClick={() => setCurrentPage((prev) => prev + 1)}
                 ></StyledButton>
               </StyledButtonContainer>
             ) : null}
           </Loading>
           <StyledCurrentCountOnTotalContainer>
             <StyledCurrentCountOnTotalText variant='sub3'>
               {tCompanies("companies.list.namesWithCurrentCountOnTotal.name", {
                 count: listOfNotifications.length,
                 total: totalNumberOfUsers,
               })}
             </StyledCurrentCountOnTotalText>
           </StyledCurrentCountOnTotalContainer>
         </StyledRoundedLeftContainer>
       </>
     </StyledContainer>
   );*/
};
